<messages>["./Domain"]</messages>

<!--
================================================================================
  Template (HTML)
================================================================================
-->

<template>
  <v-container fluid>
    <base-layout v-if="name" mw="1">
      <v-col cols="12">
        <v-toolbar>
          <i18n path="create.cloneHint">
            <b place="domainName">{{ name }}</b>
          </i18n>
        </v-toolbar>
      </v-col>
    </base-layout>
    <domain-create-update
      is-create
      :domain-data="{domain}"
      :type="type"
      @success="onSuccess"
      @dataValidityChanged="onDataValidityChanged"
      @cancel="back"/>

    <v-alert
      v-t="'general.invalid.form'"
      type="error"
      :value="isInvalidDataAlertVisible"/>
  </v-container>
</template>

<!--
================================================================================
  Logic (JavaScript)
================================================================================
-->

<script>
  import {mapMutations} from 'vuex'

  import BaseLayout from '@/app/core/components/BaseLayout'

  import DomainCreateUpdate from './DomainCreateUpdate'

  import {typeProp} from './ApplicationSupport'

  export default {
    name: 'DomainClone',

    components: {
      DomainCreateUpdate,
      BaseLayout
    },

    props: {
      name: {
        type: String,
        default: undefined
      },
      id: {
        type: [String, Number],
        default: undefined
      },
      type: typeProp
    },

    data () {
      return {
        isInvalidDataAlertVisible: false
      }
    },

    computed: {
      domain () {
        return this.name ? {name: this.name} : {id: this.id}
      }
    },

    methods: {
      ...mapMutations ({
        displayErrorMessage: 'notification/setError',
        displaySuccessMessage: 'notification/setSuccess'
      }),

      onSuccess ({successfulNames, failedNames}) {
        const count = successfulNames.length

        if (count) {
          const path = `create.success.${count > 1 ? 'multiple' : 'single'}`
          const params = count > 1
            ? {links: successfulNames.join (', ')}
            : {link: successfulNames[0]}
          this.displaySuccessMessage (this.$t (path, params))
        } else {
          this.displayErrorMessage (
            this.$tc (
              'create.failure',
              failedNames.length, {domains: failedNames.join (', ')}))
        }

        this.back ()
      },

      onDataValidityChanged (isValid) {
        this.isInvalidDataAlertVisible = !isValid
      },

      back () {
        this.$router.back ()
      }
    }
  }
</script>
